.sidebar-container {
    background-color: black;
    width: 20vw;
    min-width: 22em;
    height: 100vh;
    color: white !important;
    position: fixed;

    left: -100%;
    transition: 0.8s;
    z-index: 9999;
}

.sidebar-container.active {
    left: 0;
    transition: 0.35s;
}

.sidebar-header-container {
    padding-left: 2em;
    padding-top: 1em;
    display: flex;
    flex-direction: row;
}

.sidebar-list {
    padding: 0;
    text-decoration: none !important;
    height: 100%;
}

/* li {
    transition: background 0.2s ease-out;
} */

.sidebar-nav-link {
    text-decoration: none !important;
}

.sidebar-list-item {
    margin-top: 0.3em;
    padding-bottom: 0.7em;
    padding-top: 0.7em;
    display: flex;
    flex-direction: row;
    width: 100%;
    transition: background 0.2s ease-out;
}

.side-bar-link-icon {
    padding-left: 2em;
}

.side-bar-link-text {
    vertical-align: text-top;
    font-size: x-large;
    padding-left: 0.5em;
    text-decoration: none;
}

span {
    color: white;
}

.sidebar-list-item:hover {
    background-color: gray;
    transition: background 0.2s ease-in;
}

.top-bar {
    height: 5em;
    display: flex;
    padding: 1em;
    background-color: black;
    position: fixed;
    fill: white;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    z-index: 9999;
    align-items: center;
}

.top-bar-items {
    display: flex;
    width: 90vw;
    color: white;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-end;

    font-size: x-large;
    padding-left: 2em;
}
.top-bar-text {
    padding-top: 4px;
    padding-left: 1em;
    padding-right: 1em;

    display: flex;
    align-content: center;
}

.close-menu-icon {
    background-color: black;
    margin-left: 2em;
}
