.map-container {
    display: grid;
    place-items: center;
}

.leaflet-container {
    width: 100%;
    height: 50em;
}

.popup {
    color: black !important;
}
