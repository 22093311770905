.results-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.results-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.form-item {
    padding: 2em;
}
.card-container {
    width: 80em;
    padding-bottom: 2em;
    justify-self: center;
}
