.data-entry-container {
    margin: 2em;
    padding: 2em;
    width: 70em;

    display: grid;
    justify-content: center;
}

.card-container {
    width: 70em;
    padding-bottom: 2em;
    justify-self: center;
}
