.outer-page-container {
    width: 100vw;
    height: 100vh;

    /*for nice colour gradients see: https://uigradients.com/#RoyalBlue*/
    /*background: linear-gradient(to right, #536976, #292e49);
    color: whitesmoke;*/
}

.inner-page-container {
    display: flex;
    align-items: center;
    place-content: center;
    padding: 10em;
}

h1 {
    padding-bottom: 1em;
}

/*h3 {
    text-align: center !important;
    margin: 2em;
}*/
