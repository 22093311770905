.map-container {
    display: grid;
    width: 70em;
    margin-left: auto;
    margin-right: auto;
}

.short-form-entry {
    width: 40vh;
    padding: 1em;
}

.long-form-entry {
    width: 70em;
    padding: 1em;
}

.form-display {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.project-info-container {
    display: grid;
    align-content: center;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
    border-radius: 0.2em;
    border-color: cyan;
    border-width: 1px;
    outline: none;

    height: 2.25em;
    box-shadow: none;
}
