.short-form-entry {
    width: 40vh;
    padding: 1em;
}

.form-display {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.form-container {
    display: grid;
    align-content: center;
}

.card-container {
    width: 90em;
    padding-bottom: 2em;
    justify-self: center;
}

.legume-button {
    justify-self: right;
}

.legume-table {
    width: 90;
    justify-self: center;
}
